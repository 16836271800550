import { FeatureData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../components/View';
import Form from '../../../../../../containers/Espace/Form';
import contact from '../../../../../../params/contact/index.json';
import TemplateEspace from '../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../utils/requireUser';

const PageEspaceContactsGroupeUpdate: FC<
  PageProps & EspaceProps & UserProps
> = ({ espace, user, params: { espaceId, id } }) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        docId={id}
        itemPathnamePrefix={`/espaces/${espaceId}/contacts/groupes/`}
        model={
          new FeatureData({
            espaceId,
            collectionName: 'groupes',
            params: contact,
          })
        }
        name="item"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageEspaceContactsGroupeUpdate);
